.project > .swiper-button-prev,
.project > .swiper-button-next {
  color: white;
}

.customer .swiper {
  width: 100%;
  height: 150px;
}

.customer .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer .swiper-slide img {
  display: block;
  object-fit: cover;
  max-width: 60%;
  max-height: 45%;
}

.getintouch .swiper-button-prev {
  left: calc(95% - 40px);
  top: 70%;
}

.getintouch  .swiper-button-next {
  top: 70%;
}

@media screen and (max-width: 500px) {
  :root {
    --swiper-navigation-size: 20px;
  }

  .project .swiper-button-prev {
    left: calc(95% - 40px);
    top: 8%;
  }

  .project  .swiper-button-next {
    top: 8%;
  }
}

.test {
  position: relative;
  height: 100%;
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.test .swiper {
  width: 100%;
  height: 100px;
}

.test .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
