.my-card{
    color: red;
}

.my-card-media{
    height: auto !important;
    background-color: white !important;
    min-height: fit-content !important;
}

.my-card-subtitle{
    color: #094276 !important;
}

.my-card-text{
    color: #094276 !important;
}

.my-card-text p{
    font-size: 12px !important;
    line-height: 14px !important;
}

.my-controls{
    background-color: black !important;
}

.my-title{
    background-color: black !important;
}

.my-card-title{
    color: #094276 !important;
    font-size: 16px !important;
}

