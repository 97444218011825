@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Lexend Deca", system-ui, sans-serif;
    font-size: 20px;
    color: #094276;
    --swiper-theme-color: #094276 !important;
  }
  
}
