.admin input{
    border: 1px solid gray;
    padding: 2px;
}

.admin select{
    border: 1px solid gray;
    padding: 2px;
}

.admin button{
    border-radius: 10px;
    color: white;
    background-color: #094276;
    padding-right: 3px;
    padding-left: 3px;
}