@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");

#root .swiper .swiper-pagination .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  /* color: white !important; */
  border: 2px white solid;
}

b {
  font-weight: 800;
}

strong {
  font-weight: 800;
}

.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  background-color: #094276;
}

html {
  scroll-behavior: smooth;
}

.uppercase {
  text-transform: uppercase;
}

.font-black {
  font-weight: 900;
}

.font-extrabold {
  font-weight: 800;
}

.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.px-\[7\%\] {
  padding: 0 7%;
}

.pb-\[1\%\] {
  padding-bottom: 1%;
}

.pl-6 {
  padding-left: 1.5rem; /* 24px */
}

.pr-4 {
  padding-right: 1rem; /* 16px */
}

.py-2 {
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
}

@media (min-width: 640px) {
  .sm\:text-\[2\.5vw\] {
    font-size: 3vw;
  }

  .sm\:max-w-\[30vw\] {
    max-width: 30vw;
  }

  .sm\:ml\[10\%\] {
    margin-left: 10%;
  }

  .sm\:table {
    display: table;
  }

  .sm\:table-row-group {
    display: table-row-group;
  }
  .sm\:.table-row {
    display: table-row;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem; /* 20px */
  }
}

.items-start {
  align-items: flex-start;
}

.px-\[2vw\] {
  margin-left: 2vw;
  margin-right: 2vw;
}

.w-7 {
  width: 1.75rem; /* 28px */
}

.h-7 {
  height: 1.75rem; /* 28px */
}

.px-\[10\%\] {
  padding-left: 10%;
  padding-right: 10%;
}

.gap-10 {
  gap: 2.5rem; /* 40px */
}

.py-\[3vw\] {
  padding-top: 3vw;
  padding-bottom: 3vw;
}

.my-\[1\.5vw\] {
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
}

.mt-\[1\.5vw\] {
  margin-top: 1.5vw;
}

.py-\[10vw\] {
  padding-top: 10vw;
  padding-bottom: 4vw;
}

.py-\[5\%\] {
  padding-top: 5%;
  padding-bottom: 5%;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem; /* 16px */
  border-bottom-left-radius: 1rem; /* 16px */
}

.border-spacing-1 {
  border-spacing: 0.25rem 0.25rem;
}

.career ul{
  list-style-type: disc;
}
